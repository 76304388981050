import bottomSheet from '/~/core/bottom-sheet'
import { RewardsExtension } from '/~/core/extension'
import mdl from '/~/core/mdl'
import modals from './modals'
import bottomSheets from './modals/bottom-sheets'

mdl.register(modals)
bottomSheet.register(bottomSheets)

class FraudAndAmlScreeningExtension extends RewardsExtension {
  //
}

export default FraudAndAmlScreeningExtension
